import {
  call,
  all,
  put,
  takeLeading,
  takeEvery,
  takeLatest,
  delay,
  select,
} from 'redux-saga/effects';
import { ActionPalyoad } from 'src/helpers/redux';

import { facebookPixel } from 'src/services/analytics/providers/FacebookPixel';
import { analyticsEvents, ESubscriptionReason } from 'src/services/analytics/events';
import { IResponse, IResponseError } from 'src/services/RomanticAI/http';
import * as paymentService from 'src/services/RomanticAI/payment';

import { setSession } from 'src/services/RomanticAI/session';
import * as accountStore from 'src/redux/modules/account';
import * as dialogStore from 'src/redux/modules/dialog';

import { actions } from './actions';
import { setters } from './index';
import * as generationService from 'src/services/RomanticAI/photo-generation'


export default function* sagas() {
  yield all([
    takeLatest(
      actions.getGallery.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getGallery.pending>
      ) {
        try {
          const gallery: IResponse<generationService.IGetGalleryData> =
            yield call(generationService.getGallery, action.payload);

            if (action.payload.offset){
              yield put(setters.setGalleryMore(gallery.data));
            } else {
              yield put(setters.setGallery(gallery.data));
            }

          yield put(actions.getGallery.fulfilled());
        } catch (error) {
          yield put(actions.getGallery.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.getConfig.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getConfig.pending>
      ) {
        try {
          const config: IResponse<generationService.IGetConfigData> =
            yield call(generationService.getConfig, action.payload);

          yield put(setters.setConfig(config.data));

          yield put(actions.getConfig.fulfilled());
        } catch (error) {
          yield put(actions.getConfig.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.postUploadImage.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postUploadImage.pending>
      ) {
        try {
          const uploadImage: IResponse<generationService.IPostUploadImageData> =
            yield call(generationService.postUploadImage, action.payload);

          yield put(setters.setUploadImage(uploadImage.data));

          yield put(actions.postUploadImage.fulfilled());
        } catch (error) {
          const uploadImageError = error as IResponseError<generationService.IPostUploadImageError>
          const message = uploadImageError?.response?.data?.detail || 'Something wrong';

          yield put(actions.postUploadImage.rejected(new Error(message)));
        }
      }
    ),
    takeLeading(
      actions.postUploadImage.clear.toString(),
      function* () {
        try {
          yield put(setters.setUploadImage(undefined));
        } catch (error) {

        }
      }
    ),
    takeLeading(
      actions.postTextToImage.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postTextToImage.pending>
      ) {
        try {
          yield put(setters.setGenerated([]));

          const textToImage: IResponse<generationService.IPostTextToImageData> =
            yield call(generationService.postTextToImage, action.payload);

          yield put(setters.setGenerated(textToImage.data));
          yield put(setters.setGalleryAppend(textToImage.data.map((item) => ({ ...item, id: item.image_id, image_url: item.image }))));
          analyticsEvents.generate({
            ...action.payload,
            input_mode: 'prompt',
          })
          yield put(actions.postTextToImage.fulfilled());
        } catch (error) {
          const postTextToImage = error as IResponseError<generationService.IPostTextToImageError>
          const message = postTextToImage?.response?.data?.prompt || 'Something wrong';

          yield put(actions.postTextToImage.rejected(new Error(message)));
        }
      }
    ),

    takeLeading(
      actions.postTraitsToImage.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postTraitsToImage.pending>
      ) {
        try {
          yield put(setters.setGenerated([]));

          const traitsToImage: IResponse<generationService.IPostTraitsToImageData> =
            yield call(generationService.postTraitsToImage, action.payload);

          yield put(setters.setGenerated(traitsToImage.data));
          yield put(setters.setGalleryAppend(traitsToImage.data.map((item) => ({ ...item, id:item.image_id, image_url: item.image }))));

          analyticsEvents.generate({
            ...action.payload,
            input_mode: 'traits',
          })
          yield put(actions.postTraitsToImage.fulfilled());
        } catch (error) {
          yield put(actions.postTraitsToImage.rejected(error as Error));
        }
      }
    ),

    takeEvery(
      actions.postUnblur.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postUnblur.pending>
      ) {
        try {
          const unblur: IResponse<generationService.IPostUnblureData> =
            yield call(generationService.postUnblur, action.payload);

          yield put(setters.setUnblur(action.payload));
          yield put (accountStore.actions.changeWalletBalance.pending({ balance: unblur.data.balance }))
          yield put(actions.postUnblur.fulfilled());
        } catch (error) {
          yield put(actions.postUnblur.rejected(error as Error));
        }
      }
    ),

  ]);
}