import { createHooksActions, createHooksSelectors } from 'src/helpers/redux';
import { actions } from 'src/redux/modules/payment/actions';
import { selectors, communications } from 'src/redux/modules/payment/selectors';

export const usePaymentSelect =
  createHooksSelectors<typeof selectors>(selectors);
export const usePaymentAction = createHooksActions<typeof actions>(
  actions,
  communications
);
