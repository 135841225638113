import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux';
import { actions as moduleActions } from './actions';
import * as moduleState from './state';
import moduleSagas from './sagas';

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {

    setGallery(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['gallery']>
    ) {
      state.data.gallery = action.payload;
    },
    setConfig(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['config']>
    ) {
      state.data.config = action.payload;
    },
    setUploadImage(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['uploadImage']>
    ) {
      state.data.uploadImage = action.payload;
    },
    setGenerated(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['generated']>
    ){
      state.data.generated = action.payload
    },
    setGalleryAppend(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['gallery']['data']>
    ) {
      state.data.gallery.data = [
        ...action.payload,
        ...state.data.gallery.data,
      ]
    },
    setGalleryMore(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['gallery']>
    ) {
      state.data.gallery = {
        ...action.payload,
        data:[
        ...state.data.gallery.data,
        ...action.payload.data,
      ]
    }
    },
    setTraitsToImage(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['gallery']>
    ) {
      state.data.gallery = action.payload;
    },
    setUnblur(
      state: moduleState.IState,
      action: PayloadAction<{ image_id: number }>
    ) {
      state.data.gallery.data = state.data.gallery.data.map((item)=>({
        ...item,
        blurred: item.id === action.payload.image_id ? false: item.blurred
      }));

      state.data.generated = state.data.generated.map((item)=>({
        ...item,
        blurred: item.image_id === action.payload.image_id ? false: item.blurred
      }))
    },
    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData();
      clearAllStateCommunications(state.communication);
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions);
  },
});

export const setters = slice.actions;
export const actions = moduleActions;
export const reducers = { [moduleState.PREFIX]: slice.reducer };
export const sagas = moduleSagas;
export const state = moduleState;
