export function isPWAInstalled() {
  // Проверка display-mode
  if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
  }

  // Альтернативная проверка для некоторых браузеров (iOS Safari)
  //@ts-ignore
  if (window.navigator?.standalone === true) {
      console.log('PWA запущено в standalone режиме (iOS Safari)');
      return true;
  }

  return false;
}
