import React from 'react';

export const useBodyOverflowHidden = (isHidden = true) => {
  React.useEffect(() => {
    if (isHidden) {
      const initial = document.body.style.overflow;
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = initial;
      };
    }
  }, [isHidden]);
};
