import { createPhaseAction } from 'src/helpers/redux';

import * as botServiceTypes from 'src/services/RomanticAI/bot/types';
import * as moduleState from './state';

export const PREFIX = 'character';
export const SAGA = `${PREFIX}/saga`;

export interface ISessionStepPayload {
  step: `${moduleState.SESSION_STEPS}` | `${moduleState.SESSION_STEPS_GENERATED}` | `${moduleState.SESSION_STEPS_UPLOAD}`
}
export interface ISessionPersonaTypePayload {
  type: string
}
export const actions = {
  getCharacter: createPhaseAction<botServiceTypes.IGetBotParams>(
    `${SAGA}/getCharacter`
  ),
  postCharacter: createPhaseAction<botServiceTypes.IBot>(
    `${SAGA}/postCharacter`
  ),
  patchCharacter: createPhaseAction<botServiceTypes.IBot>(
    `${SAGA}/patchCharacter`
  ),
  getTraits: createPhaseAction<void>(`${SAGA}/getTraits`),
  getSliders: createPhaseAction<void>(`${SAGA}/getSliders`),
  getBotSessionCreate: createPhaseAction<botServiceTypes.IGetBotSessionCreateParams>(
    `${SAGA}/getBotSessionCreate`
  ),
  postBotSessionMode: createPhaseAction<botServiceTypes.IPostBotSessionModeParams>(
    `${SAGA}/postBotSessionMode`
  ),
  postBotSessionGender: createPhaseAction<botServiceTypes.IPostBotSessionGenderParams>(
    `${SAGA}/postBotSessionGender`
  ),
  postBotSessionNickName: createPhaseAction<botServiceTypes.IPostBotSessionNickNameParams>(
    `${SAGA}/postBotSessionNickName`
  ),
  postBotSessionFaceTraits: createPhaseAction<botServiceTypes.IPostBotSessionFaceTraitsParams>(
    `${SAGA}/postBotSessionFaceTraits`
  ),
  postBotSessionImage: createPhaseAction<botServiceTypes.IPostBotSessionImageParams>(
    `${SAGA}/postBotSessionImage`
  ),
  postBotSessionPersonaFacts: createPhaseAction<botServiceTypes.IPostBotSessionPersonaFactsParams>(
    `${SAGA}/postBotSessionPersonaFacts`
  ),
  postBotSessionPersonaTraits: createPhaseAction<botServiceTypes.IPostBotSessionPersonaTraitsParams>(
    `${SAGA}/postBotSessionPersonaTraits`
  ),
  postBotSessionUserImage: createPhaseAction<botServiceTypes.IPostBotSessionUserImageParams>(
    `${SAGA}/postBotSessionUserImage`
  ),
  changeSessionResults: createPhaseAction<{
    [T in keyof moduleState.IStateData['createSession']['results']]?:
    moduleState.IStateData['createSession']['results'][T]
  }>(`${SAGA}/sessionResults`),

  sessionImageProcessingRetry: createPhaseAction<void>(
    `${SAGA}/sessionImageProcessingRetry`
  ),
  sessionComplite: createPhaseAction(`${SAGA}/sessionComplite`),

  botShare: createPhaseAction<botServiceTypes.IGetBotShareParams & botServiceTypes.IPostBotShareParams>(
    `${SAGA}/botShare`
  ),
  shareBotTooltipClosed: createPhaseAction<void>(
    `${SAGA}/shareBotTooltipClosed`
  ),
  sessionStep: createPhaseAction<ISessionStepPayload>(`${SAGA}/sessionStep`),
  sessionPersonaType: createPhaseAction<ISessionPersonaTypePayload>(`${SAGA}/personaType`),
  clearSession: createPhaseAction<void>(`${SAGA}/clearSession`),

  clear: createPhaseAction<void>(`${SAGA}/clear`),
};
