import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  builderSagaCommunications,
  clearAllStateCommunications,
  ECommState,
} from 'src/helpers/redux';
import { actions as moduleActions } from './actions';
import * as moduleState from './state';
import moduleSagas from './sagas';

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setGift(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['gift']>
    ) {
      state.data.gift = action.payload;
    },

    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData();
      clearAllStateCommunications(state.communication);
    },
    setClearPostGift(state: moduleState.IState) {
      state.communication.postGift = { state: ECommState.clear }
    },
    // setTooltipHidden(state: moduleState.IState) {
    //   state.data.tooltipHidden = true
    // },

  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions);
  },
});

export const setters = slice.actions;
export const actions = moduleActions;
export const reducers = { [moduleState.PREFIX]: slice.reducer };
export const sagas = moduleSagas;
export const state = moduleState;
