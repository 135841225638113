import {
  call,
  all,
  put,
  takeLeading,
  takeEvery,
  delay,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { ActionPalyoad } from 'src/helpers/redux';
import { IResponse } from 'src/services/RomanticAI/http';
import * as dialogService from 'src/services/RomanticAI/dialog';
import * as botService from 'src/services/RomanticAI/bot';
import { analyticsEvents } from 'src/services/analytics/events';
import { actions as accountActions } from 'src/redux/modules/account/actions';
import { selectors as accountSelectors } from 'src/redux/modules/account/selectors';
import { IPricingRules } from 'src/services/RomanticAI/user';
import * as moduleState from './state';
import { actions } from './actions';
import { selectors } from './selectors';
import { setters } from './index';

import * as utils from './utils';
import { getSession, setSession } from 'src/services/RomanticAI/session';

export default function* sagas() {
  yield all([
    takeLeading(
      actions.getDialogs.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getDialogs.pending>
      ) {
        try {
          const dialogs: IResponse<dialogService.IGetDialogsData> =
            yield call(dialogService.getDialogs);

          yield put(setters.setDialogs(dialogs.data));

          yield put(actions.getDialogs.fulfilled());
        } catch (error) {
          yield put(actions.getDialogs.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getDialogHistory.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getDialogHistory.pending>
      ) {
        try {
          const { dialogId, from_message_id, count } = action.payload;
          yield put(
            setters.setBotMessageTyping(undefined)
          );

          const dialogHistory: IResponse<dialogService.IGetDialogHistoryData> =
            yield call(dialogService.getDialogHistory, {
              dialogId,
              from_message_id,
              reverse: true,
              count,
            });

          yield put(
            setters.setDialogHistory({
              ...dialogHistory.data,
              messages: dialogHistory.data.messages,
              id: dialogId,
              hasMore: dialogHistory.data.messages.length >= count
            })
          );


          yield put(actions.getDialogHistory.fulfilled());
        } catch (error) {
          yield put(actions.getDialogHistory.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getDialogHistoryMore.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getDialogHistoryMore.pending>
      ) {
        try {
          const { dialogId, from_message_id, count } = action.payload;
          yield put(
            setters.setBotMessageTyping(undefined)
          );

          const dialogHistory: IResponse<dialogService.IGetDialogHistoryData> =
            yield call(dialogService.getDialogHistory, {
              dialogId,
              from_message_id,
              reverse: true,
              count,
            });


          yield put(
            setters.setDialogHistoryAppend({
              ...dialogHistory.data,
              messages: dialogHistory.data.messages,
              id: dialogId,
              hasMore: dialogHistory.data.messages.length >= count
            })
          );
          yield delay(5000)


          yield put(actions.getDialogHistoryMore.fulfilled());
        } catch (error) {
          yield put(actions.getDialogHistoryMore.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.clearGetDialogHistory.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getDialogHistory.pending>
      ) {
        try {
          yield put(setters.setClearGetDialogHistory());
          yield put(actions.clearGetDialogHistory.fulfilled());
        } catch (error) {
          yield put(actions.clearGetDialogHistory.rejected(error as Error));
        }
      }
    ),
    takeEvery(
      actions.postDialogMessage.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postDialogMessage.pending>
      ) {
        try {
          const { dialogId, messages, has_subscribe } = action.payload;
          // const dialog: moduleState.IStateData['dialogHistory'] = yield select(selectors.dialogHistory);
          const pricingRules: IPricingRules = yield select(accountSelectors.pricingRules);

          if (pricingRules.flow === 'old') {
            // if (
            //   !has_subscribe &&
            //   dialog.bot_monetization_parameters?.count_messages_before_paywall !== undefined &&
            //   dialog.bot_monetization_parameters?.count_messages_before_paywall !== 1
            // ) {
            //   if (dialog.bot_monetization_parameters?.count_messages_before_paywall === 0) {
            //     yield put(actions.highDemandAlertOpen.pending(true));
            //     yield put(actions.postDialogMessage.fulfilled());
            //     return;
            //   }

            // } else {
              const highDemand = has_subscribe ? false : utils.getHighDemand(true, pricingRules.chat?.total_messages_before_pw);
              yield put(setters.setHighDemand(highDemand));

              if (highDemand) {
                yield put(actions.highDemandAlertOpen.pending(true));
                yield put(actions.postDialogMessage.fulfilled());
                return;
              }
            // }
          }

          yield put(
            setters.setUserMessage({
              dialogId,
              messages: messages.map(({ data }) => utils.createMessage(data)),
            })
          );

          const dialogMessage: IResponse<dialogService.IPostDialogMessageData> = yield call(dialogService.postDialogMessage, {
            has_subscribe,
            dialogId,
            messages,
          });

          yield put(accountActions.changeWalletBalance.pending({ balance: dialogMessage.data.web_balance }))

          const bot: moduleState.IStateData['bot'] = yield select(
            selectors.bot
          );

          analyticsEvents.userSentMessage({
            bot_name: bot?.name || '',
            bot_category: bot?.priority_category,
          });

          yield put(actions.postDialogMessage.fulfilled());
          yield put(setters.setBotMessageTyping(undefined));
          yield delay(1000)
          const isTyping: boolean | undefined = yield select(selectors.isTyping)
          if (isTyping === undefined) {
            yield put(
              setters.setBotMessageTyping(true)
            );
          }
        } catch (error) {
          yield put(actions.postDialogMessage.rejected(error as Error));
        }
      }
    ),

    takeEvery(
      actions.appendChatUserMessage.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.appendChatUserMessage.pending>
      ) {
        try {
          const { dialogId, messages } = action.payload;

          yield put(
            setters.setAppendChatUserMessage({ dialogId, messages })
          );
          yield put(actions.appendChatUserMessage.fulfilled());
        } catch (error) {
          yield put(actions.appendChatUserMessage.rejected(error as Error));
        }
      }),
    takeEvery(
      actions.addDialogChatBotMessage.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.addDialogChatBotMessage.pending>
      ) {
        try {
          const { dialogId, message } = action.payload;


          yield put(
            setters.setBotMessageTyping(false)
          );

          yield put(setters.setBotMessage({ dialogId, messages: [message] }));
          const bot: moduleState.IStateData['bot'] = yield select(
            selectors.bot
          );
          if (message.type === 'text') {
            analyticsEvents.botSentMessage({
              bot_name: bot?.name || '',
              bot_category: bot?.priority_category,
              sexting: Boolean(message?.is_sexting_reply),
            });
          }
          if (message.type === 'img') {
            analyticsEvents.botSentImage({
              bot_name: bot?.name || '',
              bot_category: bot?.priority_category,
              reason: message.reason || '',
              picture_generation_type: message?.context_generation_type
            });
          }

          yield put(actions.addDialogChatBotMessage.fulfilled());
        } catch (error) {
          yield put(actions.addDialogChatBotMessage.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.highDemandAlertOpen.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.highDemandAlertOpen.pending>
      ) {
        try {
          yield put(setters.setHighDemandAlertOpen(action.payload));

          yield put(actions.highDemandAlertOpen.fulfilled());
        } catch (error) {
          yield put(actions.highDemandAlertOpen.rejected(error as Error));
        }
      }
    ),
    takeEvery(
      actions.postDialogMessageUnblur.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postDialogMessageUnblur.pending>
      ) {
        try {
          const {
            dialogId,
            messageId,
            price,
            bot_name,
            priority_category,
          } = action.payload

          const message: IResponse<dialogService.IPostDialogMessageUnblurData> =
            yield call(dialogService.postDialogMessageUnblur, {
              dialogId,
              messageId,
            });

          yield put(accountActions.changeWalletBalance({
            balance: message.data.balance,
          }));
          analyticsEvents.purchasedUnblurPhoto({
            price,
            bot_name,
            bot_category: priority_category,
          })
          yield put(setters.setMessageUnblur(message.data));

          yield put(actions.postDialogMessageUnblur.fulfilled());
        } catch (error) {
          yield put(actions.postDialogMessageUnblur.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.wiriteMessage.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.wiriteMessage.pending>) {
        try {
          const { dialogId, message } = action.payload;
          const session = getSession();
          const { writeMessage = {} } = session;

          if (!message.trim()) {
            delete writeMessage[dialogId];
          } else {
            writeMessage[dialogId] = message;
          }

          setSession({
            writeMessage,
          });

          yield put(setters.setWriteMessage(writeMessage));

          yield put(actions.postDialogMessageUnblur.fulfilled());
        } catch (error) {
          yield put(actions.postDialogMessageUnblur.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.addCustomBot.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.addCustomBot.pending>) {
        // yield put(setters.setBot(action.payload));
        // yield put(setters.setSetupCategories());
      }
    ),
    takeLatest(
      actions.getBotSearch.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getBotSearch.pending>
      ) {
        try {
          yield delay(1000);
          const search: IResponse<botService.IPostBotSearchData> =
            yield call(botService.postBotSearch, action.payload);

          yield put(
            setters.setBotSearch(search.data)
          );

          analyticsEvents.searchInitiated({ searched_words: action.payload.nickname });

          yield put(actions.getBotSearch.fulfilled());
        } catch (error) {
          yield put(actions.getBotSearch.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.postBotDialog.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postBotDialog.pending>
      ) {
        try {
          const botDialog: IResponse<botService.IPostBotDialogData> =
            yield call(botService.postBotDialog, action.payload);

          //@ts-ignorec
          yield put(setters.setBot(botDialog.data));

          yield put(actions.postBotDialog.fulfilled());
          yield call(dialogService.postDialogVisit, { target_user_id: action.payload.bot_chat_user_id });
        } catch (error) {
          yield put(actions.postBotDialog.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getBotRecent.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getBotRecent.pending>
      ) {
        try {
          const botRecent: IResponse<botService.IGetBotRecentData> =
            yield call(botService.getBotRecent, action.payload);
          console.debug(action.payload.limit, botRecent.data.length)
          if (action.payload.offset) {
            yield put(setters.setBotRecentAppend({
              items: botRecent.data,
              hasMore: action.payload.limit === botRecent.data.length
            }));
          } else {
            yield put(setters.setBotRecent({
              items: botRecent.data,
              hasMore: action.payload.limit === botRecent.data.length
            }));
          }


          yield put(actions.getBotRecent.fulfilled());
        } catch (error) {
          yield put(actions.getBotRecent.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getBotCategories.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getBotCategories.pending>
      ) {
        try {
          const botCategories: IResponse<botService.IGetBotCategoriesData> =
            yield call(botService.getBotCategories, action.payload);

          yield put(setters.setBotCategories(botCategories.data));


          yield put(actions.getBotCategories.fulfilled());
        } catch (error) {
          yield put(actions.getBotCategories.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getBotCategoriesSlug.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getBotCategoriesSlug.pending>
      ) {
        try {
          const botCategories: IResponse<botService.IGetBotCategoriesSlugData> =
            yield call(botService.getBotCategoriesSlug, action.payload);

          yield put(setters.setBotCategoriesSlug({
            category_slug: action.payload.category_slug,
            bots: botCategories.data,
          }));


          yield put(actions.getBotCategoriesSlug.fulfilled());
        } catch (error) {
          yield put(actions.getBotCategoriesSlug.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.postDialogClear.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postDialogClear.pending>
      ) {
        try {
          yield call(dialogService.postDialogClear, action.payload);
          yield put(setters.setDialogClear(action.payload));

          yield put(actions.postDialogClear.fulfilled());
        } catch (error) {
          yield put(actions.postDialogClear.rejected(error as Error));
        }
      }
    ),

    takeEvery(
      actions.postImageGalleryUnblur.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postImageGalleryUnblur.pending>
      ) {
        try {
          const { image_gallery_id } = action.payload

          const imageGalleryUnblur: IResponse<dialogService.IPostImageGalleryUnblurData> =
            yield call(dialogService.postImageGalleryUnblur, { image_gallery_id });

          yield put(accountActions.changeWalletBalance({
            balance: imageGalleryUnblur.data.balance,
          }));

          yield put(setters.setImageGalleryUnblur({ image_gallery_id }));

          yield put(actions.postImageGalleryUnblur.fulfilled());
        } catch (error) {
          yield put(actions.postImageGalleryUnblur.rejected(error as Error));
        }
      }
    ),
    takeEvery(
      actions.postDialogMessageFeedback.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postDialogMessageFeedback.pending>
      ) {
        try {
          const {
            message_id,
            themes,
            feedback_text,
            feedback_type,
            message_type,
            themesNames
          } = action.payload

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const messageFeedback: IResponse<dialogService.IPostDialogMessageFeedbackData> =
            yield call(dialogService.postDialogMessageFeedback, {
              message_id, themes, feedback_text, feedback_type,
            });

          analyticsEvents.reactionSent({
            message_kind: message_type,
            reaction_type: feedback_type === 'like' ? 'upvote' : 'downvote',
            feedback: feedback_text,
            labels: themesNames
          })
          yield put(setters.setMessageFeedback({ message_id, feedback: feedback_type }));

          yield put(actions.postDialogMessageFeedback.fulfilled());
        } catch (error) {
          yield put(actions.postDialogMessageFeedback.rejected(error as Error));
        }
      }
    ),
  ]);
}
