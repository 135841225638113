import { apiV3 } from 'src/services/RomanticAI/http';
import * as types from './types';

export * from './types';


export const getConfig = (params:types.IGetConfigParams) =>
  apiV3.get<types.IGetConfigData>('/photo-generation/config', {params});


export const postUploadImage = (data:types.IPostUploadImageParams) =>
  apiV3.post<types.IPostUploadImageData>('/photo-generation/upload-image', data);

export const postTextToImage = (data:types.IPostTextToImageParams) =>
  apiV3.post<types.IPostTextToImageData>('/photo-generation/text2img', data);

export const postTraitsToImage = (data:types.IPostTraitsToImageParams) =>
  apiV3.post<types.IPostTraitsToImageData>('/photo-generation/traits2img', data);

export const postUnblur = ({ image_id }:types.IPostUnblurParams) =>
  apiV3.post<types.IPostUnblureData>(`/photo-generation/gallery/${image_id}/unblur`);

export const getGallery = (params:types.IGetGalleryParams) =>
  apiV3.get<types.IGetGalleryData>('/photo-generation/gallery', {params});
