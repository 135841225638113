import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useSearchQuery } from 'src/hooks/useSearchQuery';
import { Icon, IIconProps } from 'src/components/Icon';

import queryString from 'query-string';

import { useMediaPhone } from 'src/hooks/useMediaQuery';

import styles from './NavigationMobile.module.scss';
import { useAccountSelect } from 'src/hooks/useAccount';

const sx = classNames.bind(styles);

interface INavItemProps extends NavLinkProps {
  icon?: IIconProps['name'];
  iconProps?: IIconProps;
  label?: string;
  query?: Record<string, string>
  to: string;
  className?: string;
  activeQuery?: boolean;
}

export const NavItem = (props: INavItemProps) => {
  const { icon, iconProps, to, label, className, onClick, query, activeQuery } = props;
  const search = useSearchQuery();
  const getActive = (isActive: boolean) => {
    if (query && isActive && activeQuery) {
      return Object.keys(query).find((prop) => search.query[prop] === query[prop])
    }

    return isActive

  }

  return (
    <NavLink
      to={{
        pathname: to,
        search: query ? queryString.stringify(query) : undefined
      }}
      className={({ isActive }) => sx('item', { active: getActive(isActive) })}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
    >
      {icon && (
        <div className={sx('item-icon')}>
          <Icon {...iconProps} name={icon} />
        </div>
      )}
      {label && <div className={sx('item-label')}>{label}</div>}
    </NavLink>
  );
};

export const NavigationMobile = () => {
  const isPhone = useMediaPhone();
  const pricingRules = useAccountSelect.pricingRules()

  const isCustomBotActive = pricingRules?.custom_bot?.can_use_constructor_flow || pricingRules?.custom_bot?.can_use_user_image_flow

  if (!isPhone) return null;

  return (
    <div className={sx('container')}>
      <div className={sx('list')}>
        <NavItem icon="Home" to="/" label="Explore" />
        {isCustomBotActive && (
          <NavItem
            icon="ProfileAdd"
            to="/characters/create"
            query={{ source: 'navbar' }}
            label="Create"
            className="character-create"
          />
        )}

        {pricingRules?.photo_romantic?.can_use && (
          <>
            <NavItem icon="Camera" to="/generate-photo" query={{ tab: 'traits' }} activeQuery label="Generate" />
            <NavItem icon="Gallery" to="/generate-photo" query={{ tab: 'gallery' }} activeQuery label="Gallery" />
          </>
        )}
        <NavItem icon="Messages" to="/chats" label="Chats" />
      </div>
    </div>
  );
};
