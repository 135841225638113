import { Reducer, Store } from 'redux';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import rootSaga from './sagas';
import rootReducer from './reducer';

import { createSentryMiddleware } from './middlewares/sentryMiddleware';

const sagaMiddleware = createSagaMiddleware();
const centryMiddleware = createSentryMiddleware();


const middlewares = [
  sagaMiddleware,
  centryMiddleware
];
const staticReducers = {
  ...rootReducer,
};

const store = configureStore({
  reducer: staticReducers,
  devTools: true,
  middleware: middlewares,
});

const extendsStoreWithAsyncInjectors = (store: CustomStore) => {
  store.asyncReducers = {};
  store.injectReducer = (key, asyncReducer) => {
    // @ts-ignore
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(
      combineReducers({
        ...staticReducers,
        ...store.asyncReducers,
      })
    );
  };

  store.injectSaga = ((runSaga, rootSaga) => {
    const injectedSagas = new Map();
    const isInjected = (key: string) => injectedSagas.has(key);

    const injectNewSaga = (key: string, saga: Saga) => {
      if (isInjected(key)) return;

      const task = runSaga(saga);
      injectedSagas.set(key, task);
    };

    injectNewSaga('root', rootSaga);
    return injectNewSaga;
  })(sagaMiddleware.run, rootSaga);
};

extendsStoreWithAsyncInjectors(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type CustomStore = Store & {
  asyncReducers?: { [key: string]: Reducer };
  injectReducer?: (key: string, asyncReducer: Reducer) => void;
  injectSaga?: (runSaga: string, defaultRootSaga: Saga) => void;
};

export default store as CustomStore;
