import { createSelector } from 'reselect';
import { createCommunicationSelectors } from 'src/helpers/redux';
import { IState, PREFIX, TGlobalStateWithService } from './state';
import { actions } from './actions';

export const selectData = (state: TGlobalStateWithService): IState['data'] =>
  state[PREFIX].data;

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication;

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions);

export const selectors = {
  bot:  createSelector(selectData, (state) => state.bot),
  // getBotById: (id?: number) =>
  //   createSelector(selectData, (state) =>
  //     id ? state.bots.find((bot) => bot.id === id) : null
  //   ),
  // recentChats: createSelector(selectData, (state) => state.recentChats),
  // categoriesExploring: createSelector(
  //   selectData,
  //   (state) => state.categoriesExploring
  // ),
  isTyping: createSelector(selectData, (state) => state.isTyping),
  dialogs: createSelector(selectData, (state) => state.dialogs),
  dialogHistory: createSelector(selectData, (state) => state.dialogHistory),
  highDemand: createSelector(selectData, (state) => state.highDemand),
  isHighDemandAlertOpen: createSelector(
    selectData,
    (state) => state.isHighDemandAlertOpen
  ),
  writeMessage: createSelector(selectData, (state) => state.writeMessage),
  botSearch: createSelector(selectData, (state) => state.botSearch),
  botRecent: createSelector(selectData, (state) => state.botRecent),
  botCategories: createSelector(selectData, (state) => state.botCategories),
  botCategoriesSlug: createSelector(selectData, (state) => state.botCategoriesSlug),
};
