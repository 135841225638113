export interface IPostCreateCheckoutSessionParams {
  price_id: string;
  success_url: string;
  is_old?: boolean;
  is_upgrade?:boolean;
  metadata:{
    bot_chat_user_id?: number;
    reason: string;
    paywall_name: 'subscription' | 'subscription_sexmode' | 'hearts'
  }
}

export interface IPostCreateCheckoutSessionData {
  url: string;
}
export interface IGetSubscriptionDataStripe {
  payment_provider: string,
  user_id: string;
  price_id: string;
  is_lifetime: boolean;
  end_datetime: string | number;
  start_date: string | number;
}

export interface IGetSubscriptionDataPaypal {
  payment_provider: string,
  user_id: number;
  status: 'active' | 'created';
  paypal_subscription_id: string;
  subscription_id: number;
}
export interface IGetSubscriptionData extends IGetSubscriptionDataPaypal{
  end_datetime:string
  groups:('subscriptions' | 'sex_mode_subscriptions')[]
  is_lifetime:false
  payment_provider:"stripe"
  price_id:string;
  start_date:string;
  user_id:number
}  //IGetSubscriptionDataStripe  //& IGetSubscriptionDataPaypal

export interface IGetWalletData {
  balance: number;
}

export interface IGetProductsDataProduct {
  id: number;
  name: string;
  description: string;
  consumables: number;
  price: number;
  period?: string;
  product_type: 'SUBSCRIPTION' | ''
  payment_type?: string;
  extra?: IGetProductsDataExtra
}
export interface IGetProductsDataSubscription {
  id: number;
  name: string;
  description: string;
  period: string;
  plan_id: string;
  price: number;

  payment_type?: string;
  extra?: IGetProductsDataExtra
}
export interface IGetProductsDataExtra {
  price?: string;
  discount?: string;
  old_price?: string;
  is_popular?: boolean,
  description?: string;
}
export interface IGetProductsData {
  products: IGetProductsDataProduct[]
  subscriptions: IGetProductsDataSubscription[]
}
export type Product = IGetProductsDataSubscription | IGetProductsDataProduct;

export interface IPostOrderData {
  order_id: string;
}

enum EGetProductsGroups {
  subscripton = 'subscriptions',
  hearts = 'hearts',
  sex_mode_subscriptions = 'sex_mode_subscriptions',
}
export interface IGetProductsGroupsParams {
  groups:`${EGetProductsGroups}`[]
}


interface IProductInfo {
  analytic_price: number;
  enable: boolean;
  priority_index:number;
}
export interface IProductSubscriptionInfo extends IProductInfo {
  badge_title: string;
  save_percent: string;
  subtitle: string;
  title: string;
  total_price: string;
  old: {
    enable:boolean;
    title: string;
    subtitle:string
    previous_price:string
    save_percent:string
    badge_title: string;
    total_price: string;
  }
}
export interface IProductHeartsInfo extends IProductInfo {
  count: string;
  price: string;
  badge_title: string;
  save_percent:string
}
export interface IProductGroup<Info extends IProductInfo = IProductInfo>  {
  id: number;
  name: string,
  info:Info
  payments: {
    stripe: {
      prices: {
        stripe_price_id: string
        price: number
      }[]
    }
  }
}
export type IGetProductsGroupsData = {
  subscriptions: IProductGroup<IProductSubscriptionInfo>[],
  hearts: IProductGroup<IProductHeartsInfo>[],
  sex_mode_subscriptions: IProductGroup<IProductSubscriptionInfo>[]
  old_pricing_hearts:  IProductGroup<IProductHeartsInfo>[],
}
