import React from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { Icon } from 'src/components/Icon';
import { Spinner } from 'src/components/Spinner';
import { useBodyOverflowHidden } from 'src/hooks/useBodyOverflowHidden';
import { Eclipse } from 'src/components/Eclipse';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './Modal.module.scss';
import { useMediaPhone } from 'src/hooks/useMediaQuery';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IModalProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose?: () => void;
  containerClassName?: string;
  eclipseClassName?: string;
  phoneDrawer?: boolean;
  phoneFullscreen?: boolean;
  initOpen?: boolean
  loading?:boolean;
}

const useCloseTimer = (initOpen = true, timer: number, onClose?: () => void) => {
  const [open, setOpen] = React.useState<boolean | null>(null);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      onClose?.()
    }, timer)
  }

  React.useEffect(()=>{
    if (initOpen && open === null){
      setTimeout(()=>{setOpen(true)}, 0)
    }
    if (initOpen === false && open === true){
      handleClose()
    }
  },[initOpen, open])


  return {
    open,
    handleClose
  }
}
export const Modal = (props: IModalProps) => {
  const {
    containerClassName,
    eclipseClassName,
    children,
    className,
    onClick,
    onClose,
    phoneDrawer,
    phoneFullscreen,
    loading,
    initOpen,
  } = props;
  const id = React.useId();
  const container = document.querySelector('#modal');
  const { handleClose, open } = useCloseTimer(initOpen, 300, onClose)
  const isPhone = useMediaPhone()

  React.useEffect(() => {
    if (containerClassName && container) {
      container.classList.add(containerClassName)

      return () => container.classList.remove(containerClassName)
    }
  }, [containerClassName])

  if (!container) return <></>;

  return (
    <>
    {loading && (
      <ModalPreloader />
    )}
      {createPortal(
        <>
          <div
            id={id}
            className={cn(
              styles.container,
              className,
              {
                [styles.loading]: loading,
                [styles.phoneDrawer]: phoneDrawer,
                [styles.phoneFullscreen]: phoneFullscreen,
                [styles.phoneDrawerOpen]: open
              }
            )}
          >
            <Eclipse onClick={phoneDrawer && isPhone ? handleClose : onClose} className={cn(styles.eclipse, eclipseClassName)} />
            {children}
          </div>
        </>,
        container
      )}
    </>
  );
};

export interface IModalContentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onClose?: () => void;
  closeButtonClassName?: string;
}

export const ModalContent = (props: IModalContentProps) => {
  const { children, className, onClose, closeButtonClassName, ...attrs } =
    props;

  return (
    <div className={cn(styles.content, className)} {...attrs}>
      {onClose && (
        <div
          className={cn(styles.closeButton, closeButtonClassName)}
          onClick={onClose}
        >
          <Icon name="CloseRound" />
        </div>
      )}
      {children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IModalTitleProps
  extends React.HTMLAttributes<HTMLDivElement> { }

export const ModalTitle = (props: IModalTitleProps) => {
  const { className, children, ...attrs } = props;

  return (
    <div className={cn(styles.title, className)} {...attrs}>
      {children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IModalActionsProps
  extends React.HTMLAttributes<HTMLDivElement> { }

export const ModalActions = (props: IModalActionsProps) => {
  const { className, children, ...attrs } = props;

  return (
    <div className={cn(styles.actions, className)} {...attrs}>
      {children}
    </div>
  );
};

export const ModalPreloader = () => {
  return (
    <Modal>
      <ModalContent className={styles.spinner}>
        <CircularProgress size={80} color="secondary" />
      </ModalContent>
    </Modal>
  );
};
