import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux';
import * as moduleActions from './actions';
import * as moduleState from './state';
import moduleSagas from './sagas';

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setBotCreated(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['botCreated']>
    ){
      state.data.botCreated = action.payload;
    },
    setSliders(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['sliders']>
    ) {
      state.data.sliders = action.payload;
    },
    setTraits(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['traits']>
    ) {
      state.data.traits = action.payload;
    },
    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData();
      clearAllStateCommunications(state.communication);
    },
    setBotSessionCreate(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['create']>
    ) {
      state.data.createSession.create = action.payload;
    },
    setBotSessionMode(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['mode']>
    ) {
      state.data.createSession.mode = action.payload;
    },
    setBotSessionGender(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['gender']>
    ) {
      state.data.createSession.gender = action.payload;
    },
    setBotSessionNickName(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['nickName']>
    ) {
      const { createSession } = moduleState.getInitialData()

      state.data.createSession.nickName = {
        ...createSession.nickName,
        ...action.payload,
      };
    },
    setBotSessionFaceTraits(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['faceTraits']>
    ) {
      state.data.createSession.faceTraits = action.payload;
    },
    setBotSessionImage(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['image']>
    ) {
      state.data.createSession.image = action.payload;
    },
    setBotSessionUserImage(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['userImage']>
    ) {
      state.data.createSession.userImage = action.payload;
    },
    setBotSessionUserImageCommit(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['commit']>
    ) {
      state.data.createSession.commit = action.payload;
    },
    setBotSessionPersonaTraits(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['personaTraits']>
    ) {
      state.data.createSession.personaTraits = action.payload;
    },
    setBotSessionPersonaFactsError(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['personaFactsError']>
    ) {
      state.data.createSession.personaFactsError = action.payload
    },
    setImageProcessingLimit(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['createSession']['imageProcessingLimit']>
    ) {
      state.data.createSession.imageProcessingLimit = action.payload;
    },
    setBotSessionResult(
      state: moduleState.IState,
      action: PayloadAction<{
        [T in keyof moduleState.IStateData['createSession']['results']]?:
          moduleState.IStateData['createSession']['results'][T]
      }>
    ) {
      state.data.createSession.results = {
        ...state.data.createSession.results,
        ...action.payload,
      }
    },
    setSessionStep(
      state: moduleState.IState,
      action: PayloadAction<moduleActions.ISessionStepPayload>
    ) {
      state.data.createSession.step = action.payload.step
    },
    setSessionPersonaType(
      state: moduleState.IState,
      action: PayloadAction<moduleActions.ISessionPersonaTypePayload>
    ) {
      state.data.createSession.personaType = action.payload.type
    },
    setShareBotTooltipClosed(
      state: moduleState.IState,
      action: PayloadAction<void>
    ) {
      state.data.shareBotTooltipClosed = true
    },
    setClearSession(state: moduleState.IState) {
      state.data = moduleState.getInitialData();
      clearAllStateCommunications(state.communication);
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions.actions);
  },
});

export const setters = slice.actions;
export const actions = moduleActions.actions;
export const reducers = { [moduleState.PREFIX]: slice.reducer };
export const sagas = moduleSagas;
export const state = moduleState;
