import { createHooksActions, createHooksSelectors } from 'src/helpers/redux';
import { actions } from 'src/redux/modules/dialog/actions';
import { selectors, communications } from 'src/redux/modules/dialog/selectors';

export const useDialogSelect =
  createHooksSelectors<typeof selectors>(selectors);
export const useDialogAction = createHooksActions<typeof actions>(
  actions,
  communications
);

