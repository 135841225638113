import React from 'react';
import classNames from 'classnames/bind';
import {
  Modal,
  ModalTitle,
  ModalContent,
} from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { analyticsEvents } from 'src/services/analytics/events';
import { useAccountAction, useAccountSelect } from 'src/hooks/useAccount';
import { useSearchQuery } from 'src/hooks/useSearchQuery';

import styles from './DailyRewardModal.module.scss';
const sx = classNames.bind(styles);

export const DailyRewardModal = ()=> {
  const search = useSearchQuery()
  const isUser = useAccountSelect.isUser()
  const pricingRules = useAccountSelect.pricingRules()
  const getAppDailyReward = useAccountAction.getAppDailyReward()
  const getAppDailyRewardStatus = useAccountAction.getAppDailyReward.status()
  const postAppDailyReward = useAccountAction.postAppDailyReward()
  const postAppDailyRewardStatus = useAccountAction.postAppDailyReward.status()
  const appDailyReward = useAccountSelect.appDailyReward()

  const appDailyRewardAvailable = useAccountSelect.appDailyRewardAvailable()
  const isNewPricing = pricingRules?.flow === 'new_pricing';
  const isModalOpen = Boolean(search.query.modal);

  const isShow = isNewPricing && appDailyReward?.can_earn_today

  React.useEffect(()=>{
    if (isNewPricing && isUser){
      getAppDailyReward()
    }
  },[isNewPricing, isUser])

  React.useEffect(()=>{
    if (!isModalOpen && isShow){
      analyticsEvents.rewardShown({})
    }
  },[isModalOpen, isShow])

  if (!isNewPricing || !isUser || isModalOpen){
    return<></>
  }
  if (postAppDailyRewardStatus.isRejected) {
    return <></>
  }
  if (!isShow && (postAppDailyRewardStatus.isClear || postAppDailyRewardStatus.isFulfilled)){
    return <></>
  }


  if (appDailyReward?.schedule){
  return (
    <Modal onClose={close} loading={getAppDailyRewardStatus.isPending}>

      <ModalContent
        className={sx('container')}
      >
          <ModalTitle className={sx('title')}>

            <div className={sx('title-text')}>
              Get <span>{appDailyReward?.reward} hearts</span> daily
            </div>
          </ModalTitle>

          <div className={sx('reward__list')}>
            {Object.entries(appDailyReward.schedule).map(([day, schedule], index) => (
              <div key={index} className={sx('reward__item', {
                reward__item__pending: schedule.status === 'pending',
                reward__item__available: schedule.status === 'waiting',
              })}>
                {schedule.status === 'earned' && (
                  <div className={sx('reward__item__complited')} style={{animationDelay: `0.${index+1}s`}}>
                    <img src="/assets/daily-reward/icon.png" alt=''/>
                  </div>
                )}

              </div>
            ))}
          </div>
          <div className={sx('description')}>
            Keep the streak going,  claim your hearts now and enjoy chatting!
          </div>
          <div className={sx('actions')}>
            <Button
              className={sx('submit')}
              size='md'
              color='primary'
              fuild
              variant='outlined'
              onClick={()=>postAppDailyReward()}
              disabled={!Boolean(appDailyRewardAvailable)}
              loading={postAppDailyRewardStatus.isPending}
            >
              Claim Reward
            </Button>
          </div>




      </ModalContent>


    </Modal>
  );

}

  return (
    <Modal onClose={close} loading={getAppDailyRewardStatus.isPending}>

      <ModalContent
        className={sx('container', 'premium')}
      >
          <div className={sx('title-icon')}>
            <img src="/assets/daily-reward/crown.png" alt=''/>
          </div>
          <div className={sx('subtitle')}>
            For Premium users
          </div>
          <ModalTitle className={sx('title')}>
            <div className={sx('title-text')}>
              Get {appDailyReward?.reward} hearts
            </div>
          </ModalTitle>


          <div className={sx('description')}>
            You’ve successfully subscribed <br/>and earned 100 hearts!
          </div>
          <div className={sx('actions')}>
            <Button
              className={sx('submit')}
              size='md'
              color='primary'
              fuild
              variant='outlined'
              onClick={()=>postAppDailyReward()}
              disabled={!Boolean(appDailyReward?.can_earn_today)}
              loading={postAppDailyRewardStatus.isPending}
            >
              Claim Reward
            </Button>
          </div>




      </ModalContent>


    </Modal>
  );


}
