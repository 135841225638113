import {
  ActionReducerMapBuilder,
  // AsyncThunk,
  createAction,
  CaseReducer,
} from '@reduxjs/toolkit';
import {
  ECommState,
  ICommunication,
  IStateWithCommunication,
} from 'src/types/redux';
import { AnyAction } from 'redux';
import { TypedActionCreator } from '@reduxjs/toolkit/dist/mapBuilders';
import { NoInfer } from '@reduxjs/toolkit/dist/tsHelpers';
import { PayloadActionCreator } from '@reduxjs/toolkit/dist/createAction';
import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { createSelector, Selector } from 'reselect';
import { DefaultMemoizeOptions } from 'reselect';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

export {
  ECommState
}
export type ActionPalyoad<T extends (...args: any) => any> = PayloadAction<
  Parameters<T>[0]
>;

const clearCommunication: ICommunication = { state: ECommState.clear };
const pendingCommunication: ICommunication = { state: ECommState.pending };
const rejectedCommunication: ICommunication = { state: ECommState.rejected };
const fulfilledCommunication: ICommunication = { state: ECommState.fulfilled };

export const initialCommunication: ICommunication = { state: ECommState.clear };

type TStateWithCommunication<State> = State & IStateWithCommunication;

export type TPhaseAction<Payload, Result, Err extends Error = Error> = {
  clear: PayloadActionCreator<void>;
  pending: PayloadActionCreator<Payload>;
  fulfilled: PayloadActionCreator<Result>;
  rejected: PayloadActionCreator<Err>;
} & PayloadActionCreator<Payload>;

export interface ICommunicationReducerMap<State> {
  clear?: (state: TStateWithCommunication<State>, action: AnyAction) => State;
  pending?: (state: TStateWithCommunication<State>, action: AnyAction) => State;
  rejected?: (
    state: TStateWithCommunication<State>,
    action: AnyAction
  ) => State;
  fulfilled?: (
    state: TStateWithCommunication<State>,
    action: AnyAction
  ) => State;
}

export function communicationChannel<
  Returned,
  ThunkArg,
  // ThunkApiConfig,
  State extends IStateWithCommunication,
  ActionCreator extends TypedActionCreator<string>
>(
  thunk: // | AsyncThunk<Returned, ThunkArg, ThunkApiConfig>
  TPhaseAction<ThunkArg, Returned>,
  builder: ActionReducerMapBuilder<NoInfer<TStateWithCommunication<State>>>,
  propName: keyof TStateWithCommunication<State>['communication'],
  reducersMap?: ICommunicationReducerMap<State>
): void {
  const emptyReducerProxy = (state: TStateWithCommunication<State>) => state;
  const clearReducerProxy = reducersMap?.clear || emptyReducerProxy;
  const pendingReducerProxy = reducersMap?.pending || emptyReducerProxy;
  const rejectedReducerProxy = reducersMap?.rejected || emptyReducerProxy;
  const fulfilledReducerProxy = reducersMap?.fulfilled || emptyReducerProxy;

  const clearReducer: CaseReducer<State, ReturnType<ActionCreator>> = (
    state,
    action
  ) => {
    return clearReducerProxy(
      {
        ...state,
        communication: {
          ...state.communication,
          [propName]: {
            ...clearCommunication,
            error: undefined,
          },
        },
      } as State,
      action
    );
  };

  builder.addCase(thunk.clear.toString(), clearReducer);

  const pendingReducer: CaseReducer<State, ReturnType<ActionCreator>> = (
    state,
    action
  ) => {
    return pendingReducerProxy(
      {
        ...state,
        communication: {
          ...state.communication,
          [propName]: pendingCommunication,
        },
      } as State,
      action
    );
  };

  builder.addCase(thunk.pending.toString(), pendingReducer);

  const rejectReducer: CaseReducer<State, ReturnType<ActionCreator>> = (
    state,
    action
  ) => {
    // Catch rejected communication channel here
    return rejectedReducerProxy(
      {
        ...state,
        communication: {
          ...state.communication,
          [propName]: {
            ...rejectedCommunication,
            error: (action as AnyAction).payload,
          },
        },
      } as State,
      action
    );
  };

  builder.addCase(thunk.rejected.toString(), rejectReducer);

  const fulfilledReducer: CaseReducer<State, ReturnType<ActionCreator>> = (
    state,
    action
  ) => {
    return fulfilledReducerProxy(
      {
        ...state,
        communication: {
          ...state.communication,
          [propName]: fulfilledCommunication,
        },
      } as State,
      action
    );
  };

  builder.addCase(thunk.fulfilled.toString(), fulfilledReducer);
}

interface IBuilderSagaCommunicationsAction {
  [action: string]: TPhaseAction<any, any>;
}
export function builderSagaCommunications<
  State extends IStateWithCommunication
>(
  builder: ActionReducerMapBuilder<NoInfer<TStateWithCommunication<State>>>,
  actions: IBuilderSagaCommunicationsAction
) {
  Object.keys(actions).forEach((key) => {
    communicationChannel(actions[key], builder, key);
  });
}

type StateSagaCommunicationsMixin<T> = {
  [K in keyof T]: ICommunication;
};

interface ICreateStateSagaCommunications {
  [key: string]: TPhaseAction<any, any>;
}

export function createStateSagaCommunications<
  Actions extends ICreateStateSagaCommunications
>(actions: Actions) {
  const initial: StateSagaCommunicationsMixin<Actions> =
    {} as StateSagaCommunicationsMixin<Actions>;
  return Object.keys(actions).reduce((communications, key) => {
    const action = key as keyof Actions;
    communications[action] = initialCommunication;
    return communications;
  }, initial);
}

export type TStateCommunications<
  Actions extends { [key: string]: TPhaseAction<any, any> }
> = {
  [K in keyof Actions]: ICommunication;
};

export function createPhaseAction<PayloadType = void, ResultType = void>(
  typePrefix: string
): TPhaseAction<PayloadType, ResultType> {
  const clear = createAction<void>(`${typePrefix}/clear`);
  const pending = createAction<PayloadType>(`${typePrefix}/pending`);
  const fulfilled = createAction<ResultType>(`${typePrefix}/fulfilled`);
  const rejected = createAction(`${typePrefix}/rejected`);
  //@ts-ignore
  return Object.assign(pending, {
    clear,
    pending,
    fulfilled,
    rejected,
  }) as typeof pending & {
    clear: typeof clear;
    pending: typeof pending;
    fulfilled: typeof fulfilled;
    rejected: typeof rejected;
  };
}

export interface ICommunicationFlags {
  isError: boolean;
  isPending: boolean;
  isFulfilled: boolean;
  isRejected: boolean;
  isClear: boolean;
}
export interface ICommunicationState extends ICommunicationFlags {
  error?: Error;
  state: ECommState;
}

export const getFlagsCommunication = (
  state?: ECommState
): ICommunicationFlags => {
  return {
    isError: state === ECommState.rejected,
    isPending: state === ECommState.pending,
    isFulfilled: state === ECommState.fulfilled,
    isRejected: state === ECommState.rejected,
    isClear: state === ECommState.clear,
  };
};
export const getCommunicationState = (
  communication: ICommunication
): ICommunicationState => {
  const { state: communicationState, error } = communication;
  const flags = getFlagsCommunication(communicationState);
  return { ...flags, error, state: communicationState };
};

export const communicationMemoizeOptions: DefaultMemoizeOptions = {
  // memoizeOptions: {
  resultEqualityCheck: (a: ICommunicationState, b: ICommunicationState) =>
    a.state === b.state,
  // }
};
export const createHookDispachActionPending =
  <TPayload>(action: TPhaseAction<TPayload, undefined, Error>) =>
  () => {
    const dispatch = useDispatch();
    return React.useCallback(
      (payload: TPayload) => dispatch(action.pending(payload)),
      [dispatch]
    );
  };
export const createHookDispachActionClear =
  <TPayload>(action: TPhaseAction<TPayload, undefined, Error>) =>
  () => {
    const dispatch = useDispatch();
    return React.useCallback(() => dispatch(action.clear()), [dispatch]);
  };

export type CommunicationSelectorMixin<Actions, State> = {
  [K in keyof Actions]: (state: State) => ICommunication;
};

export const createCommunicationSelectors = <
  TGlobalState,
  TModuleState extends { [K in keyof Actions]: ICommunication },
  Actions extends ICreateStateSagaCommunications
>(
  selectState: (state: TGlobalState) => TModuleState,
  actions: Actions
) => {
  const initial: CommunicationSelectorMixin<Actions, TGlobalState> =
    {} as CommunicationSelectorMixin<Actions, TGlobalState>;
  return Object.keys(actions).reduce((communications, key) => {
    const action = key as keyof Actions;
    const selector = createSelector(
      selectState,
      (state) => {
        return getCommunicationState(state[action]);
      },
      { memoizeOptions: communicationMemoizeOptions }
    );

    communications[action] = selector;

    return communications;
  }, initial);
};

export interface ICreateHooksActionsResult<Payload> {
  (): (payload: Payload) => void;
  status: () => ICommunicationFlags;
  clear: () => () => void;
}
export type ICreateHooksActionsMixin<Actions extends ICreateHooksActions> = {
  [name in keyof Actions]: ICreateHooksActionsResult<
    Parameters<Actions[name]>[0]
  >;
};

export interface ICreateHooksActions {
  [key: string]: TPhaseAction<any, any>;
}

export function createHooksActions<Actions extends ICreateHooksActions>(
  actions: Actions,
  selectorsCommunications: CommunicationSelectorMixin<Actions, any>
) {
  const initial: ICreateHooksActionsMixin<Actions> =
    {} as ICreateHooksActionsMixin<Actions>;
  return Object.keys(actions).reduce((hooks, key) => {
    const action = key as keyof Actions;
    const selector = key as keyof CommunicationSelectorMixin<Actions, any>;

    const hook = () => {
      const hookAction = createHookDispachActionPending<
        Parameters<Actions[keyof Actions]>[0]
      >(actions[action]);
      const dispatch = hookAction();
      return dispatch;
    };
    hook.status = () => {
      // @ts-ignore
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const communications: ICommunicationFlags = useSelector(
        selectorsCommunications[selector]
      );
      return communications;
    };
    hook.clear = () => {
      const hookAction = createHookDispachActionClear(actions[action]);
      const dispatch = hookAction();
      return dispatch;
    };

    hooks[action] = hook;
    return hooks;
  }, initial);
}

export type ICreateHooksSelectrorsMixin<
  Selectors extends ICreateHooksSelectors
> = {
  [name in keyof Selectors]: () => ReturnType<Selectors[name]>;
};
export interface ICreateHooksSelectors {
  [key: string]: Selector<any, any>;
}
export function createHooksSelectors<Selectors extends ICreateHooksSelectors>(
  selectors: Selectors
) {
  const initial = {} as ICreateHooksSelectrorsMixin<Selectors>;
  return Object.keys(selectors).reduce((hooks, key) => {
    const selector = key as keyof Selectors;
    if (selector !== 'communications') {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      hooks[selector] = () => useSelector(selectors[selector]);
    }
    return hooks;
  }, initial);
}

export interface IClearAllStateCommunications {
  [key: string]: ICommunication;
}
export const clearAllStateCommunications = (
  stateCommunications: IClearAllStateCommunications
) => {
  for (const communication in stateCommunications) {
    stateCommunications[communication] = initialCommunication;
  }
};
