import { gtag, install } from 'ga-gtag';
import { getSession } from 'src/services/RomanticAI/session';
import platform from 'platform';
export {
  gtag
}

export const gtagAsync = (event: string, payload?: unknown) => new Promise<void>((resolve, reject) => {
  try {
    const data = (payload || {}) as { [key: string]: unknown }
    data._source = 'web'
    data.platform = platform.os?.family? ['iOS', 'Android'].includes(platform.os?.family||'') ?  platform.os?.family?.toLowerCase(): 'web': 'web'

    gtag('event', event, {
      event_callback: () => {
        try {
          if (
            window.location.host.includes('web-chat.gke') ||
            window.location.host.includes('localhost')
          ) {
            console.groupCollapsed(`%c${event.toUpperCase()}`, 'color: green')
            console.table(data)
            if (Array.isArray(data.items)) {
              console.table(data.items)
            }
            console.groupEnd()

          }
        } catch (error) {
          console.error(error)
        }
        resolve()
      },
      ...data,
    })

  } catch (error) {
    console.error(error)
    resolve()
  }
})

const consentDenied = {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied'
}
const consentGranted = {
  'ad_user_data': 'granted',
  'ad_personalization': 'granted',
  'ad_storage': 'granted',
  'analytics_storage': 'granted'
}

export const googleTagManagerInstall = ()=> {
  if (window.settings.GOOGLE_TAG_MANAGER) {
    install(window.settings.GOOGLE_TAG_MANAGER);
    const { confirmAdult } = getSession()

    if (!confirmAdult){
      gtag('consent', 'default', consentDenied);
    }
  }
}

export const googleTagManagerConsentGranted = ()=> {
  gtag('consent', 'update', consentGranted);
}