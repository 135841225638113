import { Button, IButtonProps } from '../Button';
import classNames from 'classnames/bind';
import { useMediaPhone } from 'src/hooks/useMediaQuery';
import styles from './AffiliateButton.module.scss';

const sx = classNames.bind(styles)

interface IAffiliateButtonProps extends IButtonProps {
  linkClassName?: string;
  mobileShort?: boolean;
}

export const AffiliateButton = (props: IAffiliateButtonProps) => {
  const { mobileShort, linkClassName, fuild, ...buttonProps } = props
  const url = window.settings.AFFILATE_LINK;
  const isPhone = useMediaPhone()
  if (!url) return <></>

  return (
    <a href={url} className={sx('link', { linkFuild: fuild }, linkClassName)} target='_blank' rel="noreferrer">
      <Button {...buttonProps} fuild={fuild} className={sx('button', buttonProps.className)}>
        {(mobileShort && isPhone) ? 'Affiliate' : 'Become an Affiliate'}
      </Button>
    </a>
  );
};