import React from 'react';
import classNames from 'classnames';

import { useBodyOverflowHidden } from 'src/hooks/useBodyOverflowHidden';

import styles from './Eclipse.module.scss';

interface IEclipseProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'children'> {
  isBodyOverflowHidden?: boolean;
}

export const Eclipse = (props: IEclipseProps) => {
  const { className, isBodyOverflowHidden = true, onClick } = props;

  useBodyOverflowHidden(isBodyOverflowHidden);

  return (
    <div
      className={classNames(styles.container, className)}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
    />
  );
};
