import React from 'react';

import { ModalPreloader } from 'src/components/Modal';
import { useSearchParams } from 'react-router-dom';

export const SearchAsyncModal = () => {
  const [searchParams] = useSearchParams();
  const modal = searchParams.get('modal');

  if (!modal) return <></>;

  //@ts-ignore
  const ModalComponent = React.lazy(() => {
    try {
      return import(`./${modal}`);
    } catch (e) {
      return () => <></>;
    }
  });

  return (
    <React.Suspense fallback={<ModalPreloader />}>
      <ModalComponent />
    </React.Suspense>
  );
};
