import React from 'react';
import classNames from 'classnames/bind';
import { format } from 'date-fns';
import { Icon } from 'src/components/Icon';
import { Button } from 'src/components/Button';
import { useNavigate } from 'react-router-dom';
import { useSearchQuery } from 'src/hooks/useSearchQuery';
import { useAccountSelect } from 'src/hooks/useAccount';

import { useMediaPhone } from 'src/hooks/useMediaQuery';
import { ICreateChatbotPayload } from 'src/services/analytics/events';
import styles from './CustomBotBanner.module.scss';

const sx = classNames.bind(styles);

export interface ISubcribleBannerProps {
  variant?: 'inline';
  text?: string;
  isViewInfo?: boolean;
}

export const CustomBotBanner = (props: ISubcribleBannerProps) => {
  const {
    variant,
    text = 'Create Unique Digital Persona',
    isViewInfo = false,
  } = props;
  const isUser = useAccountSelect.isUser();
  const navigate = useNavigate();
  const { setQuery } = useSearchQuery<{
    modal: string;
    redirectModal?: string;
    redirectPage?:string;
    source: ICreateChatbotPayload['source']
  }>();
  const isPhone = useMediaPhone();

  const handleOpenPaywall = () => {
    navigate(`/characters/create?source=banner_main`);
  };

  return (
    <div className={sx('container', variant)} onClick={handleOpenPaywall}>
      <div className={sx('title')}>
        <span className={sx('icon')}>
          <Icon name="ProfileAdd" />
        </span>
        <span className={sx('text')}>{text}</span>
      </div>
    </div>
  );
};
