import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useRouterSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const update = React.useCallback(
    (
      key: string,
      value: string | number | null | undefined,
      replace = false
    ) => {
      const search = new URLSearchParams(location.search);
      if (value) {
        search.set(key, String(value));
      } else {
        search.delete(key);
      }
      navigate(
        {
          pathname: location.pathname,
          search: search.toString(),
        },
        { replace }
      );
    },
    [location.pathname, location.search]
  );

  return update;
};
