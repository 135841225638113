import { apiV3, http } from 'src/services/RomanticAI/http';
import * as types from './types';

export * from './types';

export const postBot = (data: types.IBot) =>
  http.post<types.IBot>('/bot', data);

export const getBot = ({ botId }: types.IGetBotParams) =>
  http.get<types.IBot>(`/bot/${botId}`);

export const pathBot = () => http.patch<types.IBot>('/bot');

export const getTraits = () => http.get<types.ITraitsListItem[]>(`/trait`);

export const getSliders = () => http.get<types.ISlider>(`/slider`);

const flow = (params: { persona_type?: string }) => params.persona_type === 'upload' ? '/user-image' : ''

export const getBotSessionCreate = (params: types.IGetBotSessionCreateParams) =>
  apiV3.post<types.IGetBotSessionCreateData>(`/bot/session${flow(params)}/create`, params);

export const postBotSessionMode = (params: types.IPostBotSessionModeParams) =>
  apiV3.post<types.IPostBotSessionModeData>(`/bot/session${flow(params)}/select-mode`, params);

export const postBotSessionGender = (params: types.IPostBotSessionGenderParams) =>
  apiV3.post<types.IPostBotSessionGenderData>(`/bot/session${flow(params)}/select-gender`, params);

export const postBotSessionNickName = (params: types.IPostBotSessionNickNameParams) =>
  apiV3.post<types.IPostBotSessionNickNameData>(`/bot/session${flow(params)}/select-nickname`, params);

export const postBotSessionFaceTraits = (params: types.IPostBotSessionFaceTraitsParams) =>
  apiV3.post<types.IPostBotSessionFaceTraitsData>(`/bot/session/select-face-traits`, params);

export const postBotSessionImage = (params: types.IPostBotSessionImageParams) =>
  apiV3.post<types.IPostBotSessionImageData>(`/bot/session/select-image`, params);

export const postBotSessionPersonaTraits = (params: types.IPostBotSessionPersonaTraitsParams) =>
  apiV3.post<types.IPostBotSessionPersonaTraitsData>(`/bot/session${flow(params)}/select-persona-traits`, params);

export const postBotSessionPersonaFacts = (params: types.IPostBotSessionPersonaFactsParams) =>
  apiV3.post<types.IPostBotSessionPersonaFactsData>(`/bot/session${flow(params)}/select-persona-facts`, params);

export const postBotSessionUserImage = (params: types.IPostBotSessionUserImageParams) =>
  apiV3.post<types.IPostBotSessionUserImageData>(`/bot/session/user-image/select-user-image`, params);

export const postBotSessionUserImageCommit = (params: types.IPostBotSessionUserImageCommitParams) =>
  apiV3.post<types.IPostBotSessionUserImageCommitData>(`/bot/session/user-image/commit`, params);

export const getBotShare = (params: types.IGetBotShareParams) =>
  http.get<types.IGetBotShareData>(`/bot/share/${params.share_code}`)

export const postBotDialog = ({bot_chat_user_id, ...data}: types.IPostBotDialogParams) =>
  http.post<types.IPostBotDialogData>(`/bot/${bot_chat_user_id}/dialog`, data)

export const postBotShare = (params: types.IPostBotShareParams) =>
  http.post<types.IPostBotShareData>(`/bot/share/${params.share_code}`)

export const postBotSearch = (data: types.IPostBotSearchParams) =>
  http.post<types.IPostBotSearchData>(`/bot/search`, data)

export const getBotRecent = (params: types.IGetBotRecentParams) =>
  http.get<types.IGetBotRecentData>(`/bot/recent`, {params})

export const getBotCategories = (params: types.IGetBotCategoriesParams) =>
  http.get<types.IGetBotCategoriesData>(`/bot/categories`, {params})

export const getBotCategoriesSlug = ({category_slug, ...params}: types.IGetBotCategoriesSlugParams) =>
  http.get<types.IGetBotCategoriesSlugData>(`/bot/categories/${category_slug}`, {params})


export const postImpression = (data: types.IPostBotImpressionParams) =>
  http.post<types.IPostBotImpressionData>(`/bot/impression`, data)

