import { createPhaseAction } from 'src/helpers/redux';

import * as giftServiceTypes from 'src/services/RomanticAI/gift/types';

export const PREFIX = 'gift';
export const SAGA = `${PREFIX}/saga`;

export const actions = {
  getGift: createPhaseAction<void>(`${SAGA}/getGift`),
  postGift: createPhaseAction<
    giftServiceTypes.IPostGiftParams &
    { item: giftServiceTypes.IGiftItem, bot_name:string }
  >(
    `${SAGA}/postGift`
  ),
  clearPostGift: createPhaseAction(
    `${SAGA}/clearPostGift`
  ),
  getWallet: createPhaseAction<void>(`${SAGA}/getWallet`),
  // tooltipHidden: createPhaseAction<void>(`${SAGA}/tooltipHidden`),
};
