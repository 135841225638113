import React from 'react';
import styles from './Logotype.module.scss';
import { useMediaPhone } from 'src/hooks/useMediaQuery';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILogotypeProps extends React.HTMLAttributes<HTMLDivElement> {
  isFull?:boolean
}

export const Logotype = (props: ILogotypeProps) => {
  const { onClick, isFull } = props;
  const isPhone = useMediaPhone()

  return (
    <div onClick={onClick} className={styles.container}>
      <div className={styles.image}></div>
      {(!isPhone || isFull) && (
        <div className={styles.label}>
          Romantic <span className={styles.colorPrimary}>AI</span>
        </div>
      )}
    </div>
  );
};
