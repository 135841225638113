import React from 'react';
import { Logotype } from './Logotype';

import styles from './Header.module.scss';

export const HeaderGuiest = () => {

  return (
    <div className={styles.container}>
      <div className={styles.logotype}>
        <Logotype onClick={()=> location.reload()} isFull/>
      </div>

    </div>
  );
};
