import { createPhaseAction } from 'src/helpers/redux';

import * as genTypes from 'src/services/RomanticAI/photo-generation/types';

export const PREFIX = 'photo-generation';
export const SAGA = `${PREFIX}/saga`;

export const actions = {
  getConfig: createPhaseAction<genTypes.IGetConfigParams>(`${SAGA}/getConfig`),
  postUploadImage: createPhaseAction<genTypes.IPostUploadImageParams>(`${SAGA}/postUploadImage`),
  postTextToImage: createPhaseAction<genTypes.IPostTextToImageParams>(`${SAGA}/postTextToImage`),
  postTraitsToImage: createPhaseAction<genTypes.IPostTraitsToImageParams>(`${SAGA}/postTraitsToImage`),
  postUnblur: createPhaseAction<genTypes.IPostUnblurParams>(`${SAGA}/postUnblur`),
  getGallery: createPhaseAction<genTypes.IGetGalleryParams>(`${SAGA}/getGallery`),
};
