import store from 'src/redux';
import { http } from 'src/services/RomanticAI/http';
import * as firebaseService from 'src/services/RomanticAI/firebase';

import { actions } from './actions';

http.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    try {
      if (error?.response?.status === 401) {
        store.dispatch(actions.logout.pending());
      } else {
        // const isShowErrorNotif = !noShowErrorNotifCodes.includes(error?.response?.data?.code)
        // if (isShowErrorNotif){
        //   store.dispatch(notificationsAddAction.pending({
        //     type: 'error',
        //     params: error?.response.status === 502 ? 'Bad Gateway' : error?.response?.data?.replaces,
        //     code: error?.response?.data?.code || error?.response.status
        //   }))
        // }
      }
    } catch (e) {}
    return Promise.reject(error);
  }
);


