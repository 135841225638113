import Button from '@mui/material/Button';
import './InButton.css';
import clsx from 'clsx';

type Props = {
  isFilled?: boolean;
  isDisabled?: boolean;
  onClick?: (e?: any) => void;
  className?: string;
  children: string | React.ReactNode;
  light?: boolean;
  id: string;
  inverted?: boolean;
};

export const InButton = ({
  light = false,
  isFilled = false,
  isDisabled = false,
  children,
  onClick = () => {},
  className = '',
  id,
  inverted = false,
}: Props) => {
  const btnVariant = isFilled ? 'contained' : 'outlined';

  return (
    <Button
      variant={btnVariant}
      className={clsx(
        className,
        'inbutton',
        inverted && 'inbutton_inverted',
        light && 'light'
      )}
      onClick={onClick}
      id={id}
      disabled={isDisabled}
    >
      {children}
    </Button>
  );
};
