
import React from 'react';
import classNames from 'classnames/bind';
import { format } from 'date-fns';
import { Icon } from 'src/components/Icon';
import { Button } from 'src/components/Button';

import { useSearchQuery } from 'src/hooks/useSearchQuery';
import { useAccountSelect } from 'src/hooks/useAccount';

import styles from './SubscribleBannerNewPricing.module.scss';

const sx = classNames.bind(styles);
const valuesOptions = [
  { icon: '🎁', label: '100 daily FREE hearts' },
  { icon: '👩🏻', label: 'Create custom AI Friend' },
  { icon: '💖', label: 'Enjoy role-playing' },
]
export interface ISubscribleBannerNewPricing {
  mode?: 'navigation' | 'profile' | 'chat' | 'hearts'
}
export const SubscribleBannerNewPricing = (props: ISubscribleBannerNewPricing) => {
  const isUpgradeToSexMode = useAccountSelect.isUpgradeToSexMode()
  const isSubscribled = useAccountSelect.isSubscribled()
  const { setQuery } = useSearchQuery();

  const handleOpenPaywall = () => {
    setQuery({
      modal: 'paywall-subscription', reason: 'self',
    });
  };

  const isFullSubscription = isSubscribled && !isUpgradeToSexMode

  if (isFullSubscription && props.mode === 'navigation') {
    return <></>
  }
  if (isFullSubscription && props.mode === 'hearts') {
    return <></>
  }

  if (props.mode === 'chat'){
    return <></>
  }

  return (
    <div className={sx('container', {
      'navigation-mode': props.mode === 'navigation',
      'profile-mode': props.mode === 'profile',
      // 'chat-mode': props.mode === 'chat',
      'hearts-mode': props.mode === 'hearts',
      'upgradesex': isUpgradeToSexMode,
      'full-subscription':isFullSubscription,
    })}>

      <div className={sx('title')}>
        {props.mode === 'hearts' ? (
          <div className={sx('hearts-title')}>
            <span className={sx('text')}>Unlock <span>Sex</span> Mode</span>
            <div className={sx('hearts-description')}> Erotic pictures & sexting</div>
          </div>

        ) : <>
          {isUpgradeToSexMode ? (
            <>
              <span className={sx('text')}>Unlock <span>Sex</span> Mode</span>

            </>
          ) : isFullSubscription ? (
            <>
              <span className={sx('text')}>Enjoy <span>Sex</span> Mode</span>
            </>
          ) : (
            <>
              <span className={sx('text')}>Join</span>
              <span className={sx('icon')}>
                <Icon name="Crown" />
              </span>
              <span className={sx('grade')}>Premium</span>
            </>
          )}

        </>}
      </div>


      <div className={sx('options')}>
        {isUpgradeToSexMode ? (
          <>
            <div className={sx('options-item')}>
              <div className={sx('options-item-icon', 'lips')}></div>
              <div className={sx('options-item-label')}>Erotic pictures & sexting</div>
            </div>
          </>
        ) : isFullSubscription ? (
          <>
            <div className={sx('options-item')}>
              <div className={sx('options-item-icon', 'lips')}></div>
              <div className={sx('options-item-label')}>Generate erotic pictures & sexting</div>
            </div>
          </>
        ) : (
          <>
            {valuesOptions.map((item) => (
              <div className={sx('options-item')}>
                <div className={sx('options-item-icon')}>{item.icon} </div>
                <div className={sx('options-item-label')}>{item.label}</div>
              </div>
            ))}

          </>
        )}
      </div>

      {!isFullSubscription && (
        <Button className={sx('button')} onClick={handleOpenPaywall}>
          {props.mode === 'hearts' ? 'Try now' : (<>

            {isUpgradeToSexMode ? 'Upgrade' : 'Subscribe'}

          </>)}
        </Button>
      )}
    </div>
  );
}