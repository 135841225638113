import { useSearchQuery } from "../useSearchQuery";
import { useAccountAction, useAccountSelect } from "src/hooks/useAccount";
import React from "react";
import { isPWAInstalled } from "src/utils/isPWAInstalled";
import platform from 'platform';

const modalName = 'app-install'
const notifyModal = 'app-notify';

export interface IUseModalAppInstallQuery  {
  modal?: typeof modalName | typeof notifyModal,
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseModalAppInstallProps {

}
export const useModalAppInstall = (props?:IUseModalAppInstallProps) => {
  const isUser = useAccountSelect.isUser()
  const isSubscribed = useAccountSelect.isSubscribled()
  const user = useAccountSelect.user()
  const search = useSearchQuery<IUseModalAppInstallQuery>()
  const isAppInstall = useAccountSelect.appInstall()
  const isAppNotifyAccept = useAccountSelect.appNotifyAccept()
  const appInstall = useAccountAction.appInstall();
  const appNotifyAccept = useAccountAction.appNotifyAccept()
  const isIOS = platform.os?.family === 'iOS';
  const [isInstall, setInstall] = React.useState(Boolean(window.deferredPrompt) || isIOS)

  React.useEffect(()=>{
    if (!window.deferredPrompt){
      window.addEventListener('beforeinstallprompt', function(e){
        setInstall(Boolean(window.deferredPrompt))
      })
    }
  },[])

  const isNeedInstall = !isAppInstall && isInstall && user?.ab_web_pwa_june_2024 === 'B';
  const isNeedNotify = isPWAInstalled() && isAppNotifyAccept === undefined && user?.ab_web_pwa_june_2024 === 'B';


  const open = ()=> {
    search.setQuery({
      modal: modalName,
    });
  }
  const openNotifyAlert = ()=>{
    search.setQuery({
      modal: notifyModal,
    });
  }
  const close = () => {
    search.removeQuery([
      'modal'
    ])
  }

  const handleInstall = async () => {
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt();
      const { outcome } = await window.deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        appInstall(true)
        window.deferredPrompt = undefined;

      }
    }
  }


  const handleGetAccessNotify = ()=>{

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        appNotifyAccept(true);
      } else {
        appNotifyAccept(false);
      }
      close()
    }).catch((e)=>{
      appNotifyAccept(false);
      close()
    })
  }

  return {
    open,
    close,
    isNeedInstall,
    isNeedNotify,
    appInstall,
    handleInstall,
    openNotifyAlert,
    handleGetAccessNotify,
    isIOS,
    ...search,
  }
}