import React from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';

import { ECoinsPaywallReason } from 'src/services/analytics/events';
import { WalletButton } from 'src/components/WalletButton';
import { useRouterSearch } from 'src/hooks/useRouterSearch';
import { useAccountSelect } from 'src/hooks/useAccount';
import { Button } from 'src/components/Button';
import { Icon } from 'src/components/Icon';

import styles from './Account.module.scss';

const cx = classNames.bind(styles);

export const Account = () => {
  const params = useParams()
  const navigate = useNavigate();
  const routerSearch = useRouterSearch();
  const isUser = useAccountSelect.isUser();
  const profile = useAccountSelect.profile();

  const handleLogin = () => {
    if (isUser) return;
    routerSearch('modal', 'login');

  };
  const handleSignUp = () => {
    if (isUser) return;
    routerSearch('modal', 'sign-up');
  };
  return (
    <>
      {!isUser && (
        <>
          <Button
            className={cx('button')}
            color="secondary"
            variant="filled"
            size="md"
            onClick={handleLogin}
          >
            Log In
          </Button>
          <Button
            className={cx('button')}
            color="secondary"
            variant="filled"
            size="md"
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
        </>

      )}

      {isUser && (
        <WalletButton
          reason={params?.chatId ? ECoinsPaywallReason.chat_wallet : ECoinsPaywallReason.navbar}
        />
      )}
      {isUser && (
        <div className={cx('container')} onClick={() => navigate('/profile')}>
          <div className={cx('avatar')}>
            {profile?.photoUrl  ? (
              <img
                className={cx('avatarImage')}
                src={profile?.photoUrl}
                alt={''}
                onError={(event)=>event.currentTarget.src = `/avatar.png`}
              />
            ) : (
              <div className={cx('avatarIcon')}>
                <Icon name="ChatProfile" />
              </div>
            )}
          </div>
          <div className={cx('displayName')}>
            {profile?.displayName || profile?.email}
          </div>
        </div>
      )}
    </>
  );
};
