import { setSession, getSession } from "../RomanticAI/session";

const UTM_RETARGETING = '_ret_';
export enum EAnalyticsUTM {
  utm_source = 'utm_source',
  utm_campaign = 'utm_campaign',
  utm_medium = 'utm_medium',
  utm_term = 'utm_term',
  utm_content = 'utm_content',
  aid = 'aid',
  sub_aid = 'sub_aid',
  external_id = 'external_id',
  var1 = 'var1',
  var2 = 'var2',
  var3 = 'var3',
  var4 = 'var4',
  var5 = 'var5',
  referrer = 'referrer',
  funnel = 'funnel',
}
type TAnalyticsUTMValues = `${EAnalyticsUTM}`
export type TAnalyticsUTM = { [k in TAnalyticsUTMValues]?: string }

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAnalyticsUTM extends TAnalyticsUTM { }

export const hasAnalyticUTMRetargeting = (utm?: IAnalyticsUTM) => {
  if (!utm) return false;
  if (utm.utm_campaign?.toLowerCase().includes(UTM_RETARGETING)) return true;
  if (utm.utm_medium?.toLowerCase().includes(UTM_RETARGETING)) return true;

  return false;
}

export const initializeAnalyticsUTM = () => {
  const searchQuery = new URLSearchParams(window.location.search.toLowerCase())
  const session = getSession()
  const analyticsUTM = Object.values(EAnalyticsUTM).reduce((utm, property) => {
    const value = searchQuery.get(property) || session.analyticsUTM?.[property];
    if (value) {
      utm[property] = value
    }

    return utm
  }, {} as IAnalyticsUTM);

  setSession({
    analyticsUTM: analyticsUTM
  })
}