import React from 'react';

import {
  Modal,
  ModalTitle,
  ModalContent,
} from 'src/components/Modal';
import { Icon } from 'src/components/Icon';
import { Button } from 'src/components/Button';
import styles from './SystemError.module.scss';

export function SystemError() {

  const handleClose = () => {
    window.location.href = '/'
  };

  const hanldeTryAgain = () => {
    window.location.href = '/'
  };





  return (
    <Modal onClose={handleClose}>
      <ModalContent
        onClose={handleClose}
        className={styles.errorContainer}
        closeButtonClassName={styles.closeButton}
      >
        <Icon className={styles.errorIcon} name="Report" />
        <ModalTitle className={styles.errorTitle}>
          Something went wrong...
        </ModalTitle>
        <div className={styles.errorDescription}>
          We understand the problem and are already fixing it.
        </div>
        <Button className={styles.errorButton} onClick={hanldeTryAgain}>
          Try again
        </Button>
      </ModalContent>
    </Modal>
  );
}
