import posthog from 'posthog-js'
import platform from 'platform';

export const posthogInitialize = ()=> {
  if (
    window.settings.POSTHOG_TOKEN &&
    window.settings.POSTHOG_API_HOST
  ) {
    posthog.init(window.settings.POSTHOG_TOKEN, {
      api_host: window.settings.POSTHOG_API_HOST,
      capture_pageview: false,
      autocapture: false,
    })
  }
}

const posthogCapture = (event:string, payload?: unknown)=>{
  const data = (payload as Record<string, unknown>) || {};
  data._source = 'web'
  data.platform = platform.os?.family? ['iOS', 'Android'].includes(platform.os?.family||'') ?  platform.os?.family?.toLowerCase(): 'web': 'web'
  posthog.capture(event, data)
  try {
    if (
      window.location.host.includes('web-chat.gke') ||
      window.location.host.includes('localhost')
    ) {
      console.groupCollapsed(`%c${event.toUpperCase()}`, 'color: #fbac43')
      console.table(data)
      if (Array.isArray(data.items)) {
        console.table(data.items)
      }
      console.groupEnd()

    }
  } catch (error) {
    console.error(error)
  }
}
export {
  posthogCapture,
  posthog
}
