import { IGlobalStore } from 'src/types/store';
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux';
import { actions as sagaActions, PREFIX } from './actions';

import * as getTypes from 'src/services/RomanticAI/photo-generation/types';

export interface IStateData {
  gallery: getTypes.IGetGalleryData;
  config?: getTypes.IGetConfigData;
  uploadImage?: getTypes.IPostUploadImageData;
  generated: getTypes.IPostTextToImageData | getTypes.IPostTraitsToImageData
}

export interface IState {
  data: IStateData;
  communication: TStateCommunications<typeof sagaActions>;
}

export const getInitialData = (): IStateData => {
  return {
    generated: [],
    gallery: {
      limit:0,
      offset:0,
      count: 0,
      data:[]
    },
    config: undefined
  };
};

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
};

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState;
};

export { PREFIX };
