import axios, { AxiosResponse, AxiosPromise, InternalAxiosRequestConfig, Axios, AxiosError,  } from 'axios';
import { getSession, setSession } from './session';
import jsonToFormData from 'json-form-data'
import { userRefreshTokens } from './user';
import { analyticsEvents } from '../analytics/events';

export const baseURL = window.settings.API_URL;
export const baseURLV3 = window.settings.API_URL_V3;

export type IResponse<Data> = AxiosResponse<Data>;
export type IResponseError<Data> = { response?: AxiosResponse<Data>};
export type HTTPPromise<Data> = AxiosPromise<Data>;

export const http = axios.create({
  baseURL: baseURL,
  headers: {
    'X-Authorization-Version': 2,
    'X-User-Agent': 'web:1.0.0:null:null',
  },
});
export const apiV3 = axios.create({
  baseURL: baseURLV3,
  headers: {
    'X-User-Agent': 'web:1.0.0:null:null',
    'X-Authorization-Version': 2
  },
});

const sessionInterceptorRequest = async (config: InternalAxiosRequestConfig<any>) => {
  config.headers = config.headers || {};


  const { jwt } = getSession();
  if (jwt && !config.url?.includes('/user/refresh-tokens')){
    const maxDiff = 1000 * 60 * 5;
    const { updatedAt = 0 } = jwt;
    const now = new Date().getTime();
    const expiration = updatedAt + maxDiff

    if (expiration < now) {
      const response = await userRefreshTokens({
        refresh_token: jwt.refresh_token
      })
      setSession({
        jwt: {
          ...jwt,
          access_token:response.data.access_token,
          refresh_token: response.data.refresh_token,
          updatedAt: new Date().getTime()
        }
      })
    }
  }

  const session = getSession()

  if (session.jwt?.access_token){
    config.headers['Authorization'] = `Bearer ${session?.jwt?.access_token}`;
  }

  return config;

  // if (session?.firebaseIdToken) {
  //   config.headers['Authorization'] = `Bearer ${session.firebaseIdToken}`;
  // } else {
  //   if (session.clientToken) {
  //     config.headers['Authorization'] = `Bearer ${session.clientToken}`;
  //   }
  // }

  // if (
  //   session?.firebaseIdToken &&
  //   (session?.clientToken || session.authClientToken)
  // ) {
  //   config.headers['X-Old-Authorization'] = `Bearer ${
  //     session.authClientToken || session.clientToken
  //   }`;
  // }

  // return config;
}

// export const DataFormInterceptorMetodKey = '_method'
const recursiveFindFiles = (
  data: object | string | Array<unknown> | Blob | File
): boolean => {
  //@ts-ignore
  const isBlob = data instanceof Blob
  //@ts-ignore
  const isFile = data instanceof File
  const isFileObject = isBlob || isFile

  if (isFileObject) {
    return true
  }
  if (Array.isArray(data)) {
    return Boolean(data.find(recursiveFindFiles))
  }

  if (data !== null && typeof data === 'object') {
    //@ts-ignore
    return Boolean(Object.keys(data).find((k) => recursiveFindFiles(data[k])))
  }

  return false
}
export const DataFormInterceptorRequestFullfiled = (
  config: InternalAxiosRequestConfig
) => {
  config.headers = config.headers || {}
  const dataFormMethods = ['POST', 'PUT', 'PATCH']
  const isMethodDataForm = dataFormMethods.includes(
    config.method?.toUpperCase() as string
  )

  if (isMethodDataForm) {
    if (config.data) {
      const hasPropertyFile = recursiveFindFiles(config.data)

      if (hasPropertyFile) {
        const formData = jsonToFormData(config.data, {
          initialFormData: new FormData(),
          showLeafArrayIndexes: true,
          includeNullValues: false,
        })

        config.method = 'POST'
        config.data = formData
        config.headers['Content-Type'] =
          'multipart/form-data; boundary=something'
      }
    }
  }

  return config
}
const interceptorResponse = (config:AxiosResponse<any>)=>config
const interceptorResponseErrors = (error:any)=>{
  const axiosError = error as AxiosError;

  if (axiosError?.name === 'AxiosError'){
    const { url } = axiosError.config as { url:string }
    const { status } = axiosError.response as { status: number }
    const { code, message } = axiosError
    const { statusText } = axiosError.response as { statusText:string }
    const { data } = axiosError.response as { data?:{detail?:string} }
    const not404Errors = ['/app/daily-reward', '/payments/subscription']

    if (status === 404 && not404Errors.includes(url)){
      return Promise.reject(error);
    }

    analyticsEvents.requestError({
      code: code || status,
      message: `${message ||''}${data?.detail? `. `: ''}${data?.detail}`,
      name: statusText,
      status: status
    })
  }

  return Promise.reject(error);
}

http.interceptors.request.use(sessionInterceptorRequest);
apiV3.interceptors.request.use(sessionInterceptorRequest);
apiV3.interceptors.request.use(DataFormInterceptorRequestFullfiled);

http.interceptors.response.use(interceptorResponse, interceptorResponseErrors);
apiV3.interceptors.response.use(interceptorResponse,interceptorResponseErrors);
apiV3.interceptors.response.use(interceptorResponse, interceptorResponseErrors);