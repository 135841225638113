export type TCategoryType =
  | 'recent'
  | 'new'
  | 'my_crushes'
  | 'girlfriends'
  | 'boyfriends'
  | 'nonbinary'
  | 'custom';

export interface ICategory {
  id: number | string;
  name: string;
  description: string;
  category_type: TCategoryType;
  is_system_category: boolean;
}

export interface IUser {
  id: number;
  type: 'user' | 'bot';
}

export interface IBot extends IUser {
  avatar: string;
  description: string;
  name: string;
  gallery: string[];
  user_type: string;
  is_personal: boolean;
  is_subscribe_required: boolean;
  traits: any[];
  mood: any[];
  is_new_custom?: boolean
}
enum EMessageSubTypes {
  media_pack = 'media_pack'
}
export interface IMessageMediaItem {
  id: number;
  pack: number;
  type: 'img'
  url: string;
}
export interface IMessage {
  id: number;
  date?: string;
  type: 'text' | 'img';
  url?: string;
  src?: string;
  text?: string;
  user: IUser;
  limit_achievement_reg?: boolean;
  limit_achievement_pw?: boolean;
  is_sexting_reply?: boolean;
  is_sexting_message?: boolean;
  isSystemMessage?: boolean;
  blurred: boolean;
  reason?: string;
  context_generation_type?: 'single_message' | 'dialog_context'
  unblur_cost: number | null;
  subtype?: `${EMessageSubTypes.media_pack}`
  media?: IMessageMediaItem[];
  media_id?: string;
  feedback?: string;
}

export interface ICategoryMapping {
  category_id: number;
  bot_id: number;
  message_id: number;
  is_visit: boolean;
}
export interface IGetDialogsDataItem extends IBot {
  last_message: IMessage
}
export interface IGetDialogCategorizedData {
  categories: ICategory[];
  bots: IBot[];
  messages: IMessage[];
  categories_mapping: ICategoryMapping[];
}
export type IGetDialogsData = IGetDialogsDataItem[]

export interface IGetDialogHistoryParams {
  dialogId: number;
  from_message_id?: number;
  reverse?: boolean;
  count: number;
}

export interface IGetDialogHistoryDataFilters {
  count: number;
  from_message_id: number | null;
  reverse: boolean;
}
export interface IBotMonetizationParameters {
  count_messages_before_paywall?: number;
  count_photos_without_blur?: number;
  count_sexting_messages_without_blur?: number;
}
export interface IGetDialogHistoryData {
  id: number;
  count: number;
  bot_monetization_parameters?: IBotMonetizationParameters;
  filters: IGetDialogHistoryDataFilters;
  messages: IMessage[];
}

export interface IPostDialogMessageParamsMessages {
  messageType: string;
  data: string;
}

export interface IPostDialogMessageParams {
  dialogId: number;
  has_subscribe: boolean;
  messages: IPostDialogMessageParamsMessages[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPostDialogMessageData {
  web_balance: number
}

export interface IPostDialogMessageUnblurParams {
  dialogId: number;
  messageId: number;
}

export interface IPostDialogMessageUnblurData extends IMessage {
  balance: number;
  user: IBot;
}

export interface IPostImageGalleryUnblurParams {
  image_gallery_id: number;
}

export interface IPostImageGalleryUnblurData {
  balance: number;
  blurred: boolean;
  photo_cost: number;
}

export interface IPostDialogVisitParams {
  target_user_id: number;
}
export interface IPostDialogVisitData {
  status: string
}

export interface IPostDialogClearParams {
  dialogId: number;
}


export interface IPostDialogMessageFeedbackParams {
  message_id: number
  feedback_type: 'like' | 'dislike';
  themes?: number[]
  feedback_text?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPostDialogMessageFeedbackData {

}