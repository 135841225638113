import { createHooksActions, createHooksSelectors } from 'src/helpers/redux';
import { actions } from 'src/redux/modules/account/actions';
import { selectors, communications } from 'src/redux/modules/account/selectors';

export const useAccountSelect =
  createHooksSelectors<typeof selectors>(selectors);
export const useAccountAction = createHooksActions<typeof actions>(
  actions,
  communications
);
